import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from '../../components/project_page/ProjectPageWrapper';
import TextBlock from '../../components/project_page/TextBlock';
import Mobile3 from '../../components/project_page/Mobile3';
import Web1 from '../../components/project_page/Web1';
import Image1 from '../../components/project_page/Image1';
import ImageGrid from '../../components/project_page/ImageGrid';
import YoutubeWrap from '../../components/project_page/image_wrappers/YoutubeWrap';

import globalStyles from '../global.module.css';

const headerBackground = "#fff3f9"
const navColor="#222";
const Meetloaf = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Meetloaf"
      link="https://meetloaf.co"
      oneLiner="The Dream Team Machine"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Problem"
          headline={`As companies grow, people silo`}
          content={`
            As teams and companies grow, employee relationships become more
            distant and formal. Employees know less about what’s going outside
            of their own teams and departments. “Teams” become “companies”
            and eventually just “places to work”. Employees that feel disconnected
            from the broader company are more likely to be disengaged at work.`}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="The Product"
          headline={`Promote a tighter, more connected company culture through easy to setup 1:1s`}
          content={`Meetloaf automatically creates and schedules virtual 1:1s
            for your teammates to get to know each other better, catchup and
            learn something new. Whether you're a team of 10 or 1,000 - Meetloaf
            pairs and schedules everyone in under a minute.`
          }
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <YoutubeWrap src="https://www.youtube.com/embed/wxHn9EqEqAM" />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Web1
          desktopImage={<Img fluid={data.body1.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
          <TextBlock
            title="Design"
            headline={`Defaults have power`}
            content={`
              Meetloaf meetings get dropped directly into free times on employee calendars.
              No back and forth with the person you're paired with or need for
              one person to be the 'planner'. We found many people using similar
              tools that didn't do the scheduling rarely actually met with their pair.
              Meetloaf solves this drop-off by simply making a scheduled meeting the default.
            `
            }
          />
      </div>

      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          desktopImage={<Img fluid={data.body2.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body4.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
          <TextBlock
            title="Engineering"
            headline={`Automatic Scheduling`}
            content={`Meetloaf integrates directly with Google Calendar and
              Microsoft Outlook to use employee schedules to both pair and
              schedule 1:1 meetings, typically in under a minute. We use background job
              processing to ensure that regardless of company size, everyone gets
              scheduled.`
            }
          />
      </div>
      <div>
        <Image1 img={<Img fluid={data.body5.childImageSharp.fluid} />} />
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Meetloaf

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/meetloaf/header2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileHeader: file(relativePath: { eq: "projects/meetloaf/header-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body1: file(relativePath: { eq: "projects/meetloaf/web1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body2: file(relativePath: { eq: "projects/meetloaf/web2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body3: file(relativePath: { eq: "projects/meetloaf/web3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body4: file(relativePath: { eq: "projects/meetloaf/web4.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body5: file(relativePath: { eq: "projects/meetloaf/cal.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
